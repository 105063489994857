import React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import {
  Box,
  Grid,
  InputAdornment,
  TextField,
  Typography,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
} from "@mui/material";
import { IoLogoWhatsapp } from "react-icons/io";
import { RiTelegramFill } from "react-icons/ri";

import { useParams } from "react-router";
import { latestPost } from "components/BlogComponent/Blog.content";
const SHEET_URL =
  " https://api.steinhq.com/v1/storages/67b2bfc5c08833336575720c";

const recentPosts = [
  "Public Administration Officer",
  "Assistant Government Coordinator",
  "Junior Administrative Clerk",
  "State Welfare Inspector",
  "Government Data Entry Operator",
  "Municipal Services Officer",
  "Transport Department Assistant",
];

function JobDetailsTable({ jobDetails, namePost }) {
  console.log(jobDetails);

  if (!jobDetails) {
    return <LinearProgress />; // Or a better loading indicator
  }
  const jobDetailsData = [
    { no: 1, label: "Organisation/Company", value: jobDetails.organization },
    { no: 2, label: "Name of the Post", value: jobDetails.name_of_post },
    { no: 3, label: "No. of Vacancies", value: jobDetails.vacancies },
    {
      no: 4,
      label: "Starting Date to Apply",
      value: jobDetails.starting_date_to_apply,
    },
    {
      no: 5,
      label: "Experience Required",
      value: jobDetails.experience_required,
    },
    {
      no: 6,
      label: "Last date to Apply",
      value: jobDetails.last_date_to_apply,
    },
    { no: 7, label: "Age Limit", value: jobDetails.age_limit },
    {
      no: 8,
      label: "Educational Qualification",
      value: jobDetails.educational_qualification,
    },
    { no: 9, label: "ADVT. No.", value: jobDetails.advt_no },
    { no: 10, label: "Application Fees", value: jobDetails.application_fees },
    { no: 11, label: "Selection Process", value: jobDetails.selection_process },
    { no: 12, label: "Official Website", value: jobDetails.official_website },
    { no: 13, label: "Salary", value: jobDetails.salary },
  ];
  return (
    <Grid container sx={{ mt: 4, width: "100%" }}>
      <Typography
        sx={{
          backgroundColor: "#0056b3",
          color: "white",
          fontSize: "20px",
          fontWeight: "bold",
          textAlign: "center",
          padding: "10px",
          border: "1px solid #232323",
          width: "100%",
        }}>
        {" "}
        {namePost}
      </Typography>
      <TableContainer component={Paper} sx={{ width: "100%" }}>
        {/* Table Heading */}

        <Table>
          <TableBody>
            {/* Data Rows */}
            {jobDetailsData.map((item, index) => (
              <TableRow key={index} sx={{ border: "1px solid #232323" }}>
                <TableCell
                  sx={{
                    fontFamily: "Manrope",
                    fontWeight: "700",
                    textAlign: "center",
                    width: "10%",
                    backgroundColor: "#f0f0f0",
                    border: "1px solid #232323",
                  }}>
                  {item.no}
                </TableCell>
                <TableCell
                  sx={{
                    fontFamily: "Manrope",
                    fontWeight: "700",
                    backgroundColor: "#f0f0f0",
                    width: "35%",
                    border: "1px solid #232323",
                  }}>
                  {item.label}
                </TableCell>
                <TableCell
                  sx={{
                    fontFamily: "Manrope",
                    fontWeight: "700",
                    whiteSpace: "pre-line",
                    width: "55%",
                    backgroundColor: "#f0f0f0",
                    border: "1px solid #232323",
                  }}>
                  {item?.value?.split("\\n").map((line, i) => {
                    // Regular expression to match URLs that start with "www."
                    const urlRegex = /(www\.[^\s]+)/g;

                    return (
                      <React.Fragment key={i}>
                        {line.split(urlRegex).map((part, j) =>
                          part.match(urlRegex) ? (
                            <a
                              key={j}
                              href={`https://${part}`} // Ensuring it has https://
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{
                                color: "#007bff",
                                textDecoration: "underline",
                              }}>
                              {part}
                            </a>
                          ) : (
                            part
                          )
                        )}
                        <br />
                      </React.Fragment>
                    );
                  })}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
}

function Jobpage() {
  const [careerData, setCareerData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [recentPosts, setRecentPosts] = useState([]);
  const [error, setError] = useState(null);
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get(`${SHEET_URL}/Sheet1`) // Fetching data from the sheet
      .then((response) => {
        setLoading(false);
        const allJobs = response.data;

        // Find the specific job based on the matching `common_post_name`
        const specificJob = response.data.find(
          (item) => item.common_post_name === id
        );

        if (specificJob) {
          setCareerData(specificJob);
        } else {
          setError("Job details not found.");
        }
        const numRecentPosts = Math.min(6, allJobs.length);
        const latestJobs = allJobs
          .slice(allJobs.length - numRecentPosts) // Slice from the end
          .map((job) => job.common_post_name);

        setRecentPosts(latestJobs);
      })
      .catch((err) => {
        setLoading(false);
        setError(err.message);
      });
  }, [id]);

  if (loading) return <LinearProgress />;
  if (error) return <p>{error}</p>;
  if (!careerData) return <p>No job data found.</p>;

  console.log(recentPosts);

  const {
    organization,
    common_post_name,
    description_1,
    description_2,
    name_of_post,
    vacancies,
    starting_date_to_apply,
    last_date_to_apply,
    age_limit,
    educational_qualification,
    advt_no,
    experience_required,
    application_fees,
    selection_process,
    official_website,
    salary,
    yt_link,
  } = careerData || {};

  return (
    <Grid container sx={{ width: "100%", px: { xs: 4, sm: 6, md: 8 } }}>
      <Grid container sx={{ marginTop: "50px" }}>
        <Grid container spacing={5}>
          <Grid item xs={12} sm={12} md={6} lg={8}>
            <Typography
              fontFamily="Tiempos Text Medium Italic"
              sx={{
                fontSize: { xs: "30px", md: "38px", lg: "40px" },
                fontWeight: "400",
                lineHeight: 1.2,
                fontStyle: "italic",
              }}>
              {organization}
            </Typography>
            <Typography
              fontFamily="Manrope"
              sx={{
                fontSize: { xs: "14px", sm: "16px", md: "16px" },
                fontWeight: 600,
                mt: 1,
              }}>
              {description_1}
            </Typography>
            <Typography
              fontFamily="Manrope"
              sx={{
                fontSize: { xs: "14px", sm: "16px", md: "16px" },
                fontWeight: 600,
                mt: 1,
              }}>
              {description_2}
            </Typography>
            <Grid>
              <JobDetailsTable
                jobDetails={careerData}
                namePost={common_post_name}
              />
            </Grid>
            {yt_link && (
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}>
                <iframe
                  width="100%"
                  height="262"
                  src={yt_link}
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowfullscreen></iframe>
              </Box>
            )}
            <Grid container sx={{ marginTop: "10px" }}>
              <Grid item xs={5}>
                <a
                  href="https://chat.whatsapp.com/HUqTIpPGJ94086RnSEmlS8"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: "none" }}>
                  <Grid container>
                    <Grid item xs={2} md={1}>
                      <IoLogoWhatsapp color="green" size={20} />{" "}
                    </Grid>{" "}
                    <Grid item xs={10} md={11}>
                      <Typography
                        sx={{
                          fontSize: {
                            xl: "10px",
                            sm: "10px",
                            md: "16px",
                            lg: "16px",
                            pl: "3px",
                          },
                          fontWeight: "400",
                        }}>
                        Click to Join WhatsApp Community
                      </Typography>
                    </Grid>
                  </Grid>
                </a>
              </Grid>
              <Grid item xs={5}>
                {" "}
                <a
                  href="https://t.me/msigmagokulam_corecareerhub"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: "none" }}>
                  <Grid container>
                    <Grid item xs={2} md={1}>
                      <RiTelegramFill
                        color="#24A1DE
"
                        size={20}
                      />
                    </Grid>{" "}
                    <Grid item xs={10} md={11}>
                      <Typography
                        sx={{
                          fontSize: { md: "15px", lg: "15px" },
                          fontWeight: "400",
                        }}>
                        Click to Join Telegram Community
                      </Typography>
                    </Grid>
                  </Grid>
                </a>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={4}
            sx={{
              display: "flex",
              justifyContent: "center",
            }}>
            <Paper
              sx={{
                maxWidth: { md: "100%", lg: "320" },
                maxHeight: 280,
                backgroundColor: "#f8f8f8",
              }}>
              {/* Green Header */}
              <Box
                sx={{
                  backgroundColor: "#2ca04c",
                  color: "white",
                  textAlign: "center",
                  py: 1,
                  fontWeight: "bold",
                }}>
                Recent Posts
              </Box>

              {/* List of Recent Posts */}
              <Box
                sx={{
                  p: 1,

                  overflow: "auto",
                  maxHeight: 210,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "left",
                }}>
                {recentPosts.map((post, index) => (
                  <Typography
                    key={index}
                    onClick={() =>
                      navigate(`/core-career-hub/${encodeURIComponent(post)}`)
                    }
                    sx={{
                      fontSize: "13px",
                      fontWeight: "600",
                      color: "#333",
                      py: 1,
                      px: 2,
                      cursor: "pointer",
                      width: "100%",
                      textAlign: "left",
                      transition:
                        "color 0.3s ease-in-out, background-color 0.3s ease-in-out", // Smooth transition effect
                      "&:hover": {
                        color: "#007bff",
                        backgroundColor: "#d1e7ff", // Highlight on hover
                      },
                    }}>
                    ➡ {post}
                  </Typography>
                ))}
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Jobpage;
