import React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import {
  Box,
  Grid,
  InputAdornment,
  TextField,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  LinearProgress,
  Card,
  CardContent,
} from "@mui/material";

const SHEET_URL =
  " https://api.steinhq.com/v1/storages/67b2bfc5c08833336575720c";

function CoreCareerHub() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get(`${SHEET_URL}/Sheet1`) // Replace "Sheet1" with your actual sheet name
      .then((response) => {
        setData(response.data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error.message);
        setLoading(false);
      });
  }, []);

  if (loading) return <LinearProgress />;
  console.log(data);

  return (
    <Grid container sx={{ width: "100%", px: { xs: 4, sm: 6, md: 8 } }}>
      {" "}
      <Grid container spacing={5} sx={{ mt: 1 }}>
        <Grid item xs={12}>
          <Typography
            fontFamily="Tiempos Text Medium Italic"
            sx={{
              fontSize: { xs: "30px", sm: "30px", md: "38px", lg: "40px" },
              fontWeight: "400",
              lineHeight: 1.2,
              fontStyle: "italic",
              textAlign: "center",
            }}>
            Core Career Hub
          </Typography>
        </Grid>
        <Grid item xs={12}>
          {" "}
          <Grid item xs={12}>
            <Grid container spacing={2}>
              {data
                .slice()
                .reverse()
                .map((job, index) => {
                  // Combine status_1 and status_2 into an array (filter out empty values)
                  const statuses = [job.status_1, job.status_2].filter(Boolean);

                  return (
                    <Grid item xs={12} sm={6} md={6} lg={4} xl={3} key={index}>
                      <Card
                        onClick={() =>
                          navigate(
                            `/core-career-hub/${encodeURIComponent(
                              job.common_post_name
                            )}`
                          )
                        }
                        sx={{
                          cursor: "pointer",
                          transition: "0.3s",
                          "&:hover": { transform: "scale(1.05)" },
                          backgroundColor: "#f8f9fa",
                          borderRadius: "10px",
                          boxShadow: "2px 4px 10px rgba(0,0,0,0.1)",
                          width: "100%",
                          maxWidth: 350,
                          position: "relative", // Ensure absolute positioning of ribbons
                          paddingTop: "30px", // Creates space to avoid overlap with ribbons
                        }}>
                        {/* Multiple Ribbons */}
                        {statuses.length > 0 && (
                          <Box
                            sx={{
                              position: "absolute",
                              top: 0,
                              left: 0,
                              display: "flex",
                              gap: "0px", // Adds space between ribbons
                            }}>
                            {statuses.map((status, i) => (
                              <Box
                                key={i}
                                sx={{
                                  backgroundColor:
                                    status === "New"
                                      ? "#27ae60"
                                      : status === "Must Apply"
                                      ? "green"
                                      : "black",
                                  color: "white",
                                  fontSize: "10px",
                                  fontWeight: "bold",
                                  padding: "4px 10px",

                                  boxShadow: "1px 1px 5px rgba(0,0,0,0.1)",
                                }}>
                                {status}
                              </Box>
                            ))}
                          </Box>
                        )}

                        <CardContent>
                          <Typography
                            fontSize="12px"
                            fontWeight="bold"
                            color="#2c3e50">
                            {" "}
                            {job.organization}
                          </Typography>
                          <Typography fontSize="11px" color="textSecondary">
                            {job.common_post_name}
                          </Typography>
                          <Typography fontSize="11px" color="textSecondary">
                            {job.educational_qualification}
                          </Typography>
                          <Box>
                            <Typography
                              fontSize="11px"
                              fontWeight="600"
                              color="#27ae60">
                              Last Date: {job.last_date_to_apply}
                            </Typography>
                          </Box>
                          <Box mt={1}>
                            <Button
                              sx={{
                                textTransform: "none",
                                backgroundColor: "blue",
                                "&:hover": {
                                  backgroundColor: "black",
                                },
                              }}>
                              <Typography fontSize="11px" color="white">
                                View Details
                              </Typography>
                            </Button>
                          </Box>
                        </CardContent>
                      </Card>
                    </Grid>
                  );
                })}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default CoreCareerHub;
