import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import styles from "./home.module.css";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { Container } from "@mui/material";
import Invitation from "../../assets/ads/Invitation.png";
import image from "../../assets/ads/placementdrive.png";
// import onlineLaunch from "../../assets/images/onlineLaunch.jpg";
import popupImage from "../../assets/images/popup.webp";

function AdsPopup({ onclose }) {
  const [open, setOpen] = useState(true);

  const navigate = useNavigate();

  const handleRedirect = () => {
    window.open(
      "https://www.youtube.com/live/zVzoip6T3_s?si=pzhF_SNBVzw5Onvu",
      "_blank"
    );
  };

  const handleClose = () => {
    if (onclose) {
      onclose();
    }
  };

  return (
    <Container component="main" maxWidth="300px">
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth={"sm"}
        PaperProps={{
          style: {
            backgroundColor: "transparent",
            boxShadow: "none",
          },
        }}
      >
        <Grid
          container
          justifyContent="flex-end"
          alignItems="flex-start"
          sx={{
            background: "transparent",
            padding: 1,
            position: "absolute",
          }}
        >
          <IconButton
            onClick={handleClose}
            sx={{
              backgroundColor: "#00000070",
              color: "white",
              Left: "5px",
              fontWeight: "900",
            }}
          >
            <CloseIcon />
          </IconButton>
        </Grid>
        <DialogContent
          onClick={handleRedirect}
          sx={{ padding: 0, overflow: "auto", scrollbarWidth: "none" }}
        >
          <Grid container>
            <Grid item sx={{ paddingTop: "0px !important" }}>
              <img src={popupImage} alt="" className={styles.mainImage} />
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </Container>
  );
}

export default AdsPopup;
