import { Button } from "@mui/joy";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";

import { getCourseOptions, submitLead } from "../../api/npf";
import sendEmail from "api/sentformspark";
// Form schema
const formSchema = Yup.object({
  course: Yup.string().required("Please select a course"),
  fullName: Yup.string()
    .min(3, "Too Short!")
    .max(50, "Too Long!")
    .required("Full Name Required"),
  phoneNumber: Yup.string()
    .min(10, "Too Short!")
    .max(50, "Too Long!")
    .required("Phone Number Required"),
  email: Yup.string().email("Invalid email").required("Email ID Required"),
});

const courseList = ["Zero to Infinity", "Gate", "B.Tech", "ISRO", "ESE"];

const ContactForm = () => {
  const [course, setCourse] = useState("");
  const [loading, setLoading] = useState(false);
  const formId = "9EYLl236S";

  const handleChange = (event) => {
    setCourse(event.target.value);
  };

  // formik
  const formik = useFormik({
    initialValues: {
      course: "",
      // fullName: "",
      firstName: "",
      phoneNumber: "",
      email: "",
    },
    onSubmit: async (values, { resetForm }) => {
      setLoading(true);
      const formData = [
        {
          Attribute: "Course",
          Value: values.course,
        },
        {
          Attribute: "FirstName",
          Value: values.fullName,
        },
        {
          Attribute: "Phone",
          Value: values.phoneNumber,
        },
        {
          Attribute: "EmailAddress",
          Value: values.email,
        },
      ];

      const accessKey = "u$r4dc87d33ffe8573c649bbd42b9359abd";
      const secretKey = "9b68b157fe7def3bb13a053ef0ceb313b6f7226d";
      const host = "https://api-in21.leadsquared.com/v2/";

      const apiUrl = `${host}LeadManagement.svc/Lead.Capture?accessKey=${accessKey}&secretKey=${secretKey}`;

      try {
        const response = await fetch(apiUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        });

        const data = await response.json();

        if (response.ok) {
          setLoading(false);
          alert("Submitted, we will contact you");

          resetForm({
            values: {
              course: "",
              firstName: "",
              phoneNumber: "",
              email: "",
            },
          });
          setCourse("");
        } else {
          throw new Error(data.message || "Submission failed");
        }
      } catch (error) {
        setLoading(false);
        alert(`Error: ${error.message}`);
      }
    },
    validationSchema: formSchema,
  });

  return (
    <Box
      sx={{
        width: { xs: 320, md: 600 },
      }}>
      <form
        onSubmit={formik.handleSubmit}
        style={{ width: "100%", marginTop: "16px" }}>
        <FormControl fullWidth variant="standard" sx={{ mt: 4 }}>
          <InputLabel
            id="course-label"
            sx={{ px: 0, fontFamily: "Manrope", fontWeight: "500" }}>
            Select course you’re interested in
          </InputLabel>
          <Select
            id="course"
            name="course"
            value={formik.values.course}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            labelId="course-label"
            sx={{
              width: "100%",
              "&:hover": {
                backgroundColor: "#ffffff",
              },
              "&:active": {
                backgroundColor: "#ffffff",
              },
            }}>
            {courseList.map((courses, index) => (
              <MenuItem
                key={index}
                value={courses}
                sx={{
                  fontFamily: "Manrope",
                  "&:hover": {
                    backgroundColor: "#ffffff",
                    color: "#43BA80",
                  },
                  "&:active": {
                    backgroundColor: "#ffffff",
                    color: "#43BA80",
                  },
                }}>
                {courses}
              </MenuItem>
            ))}
          </Select>
          <Typography sx={{ color: "#fc0303", fontFamily: "Manrope", mt: 1 }}>
            {formik.touched.course && formik.errors.course}
          </Typography>
        </FormControl>

        <TextField
          value={formik.values.fullName}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          id="fullName"
          name="fullName"
          variant="standard"
          label="Full Name"
          sx={{
            width: "100%",
            mt: 4,
            "& label": {
              fontFamily: "Manrope",
              fontWeight: 500,
            },
            "& input": {
              fontFamily: "Manrope",
              fontWeight: 500,
            },
          }}
        />
        <Typography sx={{ color: "#fc0303", fontFamily: "Manrope" }}>
          {formik.touched.fullName && formik.errors.fullName}
        </Typography>

        <TextField
          value={formik.values.phoneNumber}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          id="phoneNumber"
          name="phoneNumber"
          variant="standard"
          label="Phone Number"
          sx={{
            width: "100%",
            mt: 4,
            "& label": {
              fontFamily: "Manrope",
              fontWeight: 500,
            },
            "& input": {
              fontFamily: "Manrope",
              fontWeight: 500,
            },
          }}
        />
        <Typography sx={{ color: "#fc0303", fontFamily: "Manrope" }}>
          {formik.touched.phoneNumber && formik.errors.phoneNumber}
        </Typography>

        <TextField
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          id="email"
          name="email"
          variant="standard"
          label="Email"
          sx={{
            width: "100%",
            mt: 4,
            "& label": {
              fontFamily: "Manrope",
              fontWeight: 500,
            },
            "& input": {
              fontFamily: "Manrope",
              fontWeight: 500,
            },
          }}
        />
        <Typography sx={{ color: "#fc0303", fontFamily: "Manrope" }}>
          {formik.touched.email && formik.errors.email}
        </Typography>

        <Button
          type="submit"
          fontFamily="Manrope"
          style={{
            backgroundColor: "#3878f2",
          }}
          disabled={loading}
          sx={{
            borderRadius: "7px",
            py: 1.5,
            color: "#FFFFFF",
            textAlign: "center",
            px: 2,
            fontSize: "14px",
            textTransform: "none",
            mt: 4,
            ml: { md: 60 },
            height: "42px",
            width: "125px",
          }}>
          {loading ? "Sending..." : "Send"}
        </Button>
      </form>
    </Box>
  );
};

export default ContactForm;
