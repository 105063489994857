import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Divider,
  Button,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import Logo from "../../assets/logo/newLogo.png";
import mobLogo from "../../assets/logo/logo-mob.png";
import style from "./Navbar.module.scss";
import { Box } from "@mui/system";

const MENU_LIST = [
  { text: "Home", href: "/" },
  { text: "About Us", href: "/about" },
  { text: "Zero to Infinity", href: "https://learn.msigmagokulam.com/login" },
  { text: "Courses", href: "/courses" },
  { text: "Careers", href: "/hiring" },
  { text: "Core Career Hub", href: "/core-career-hub" },
  { text: "Contact Us", href: "/contact" },
];

const Navbar = () => {
  const location = useLocation();
  const [activeIndex, setActiveIndex] = useState(
    MENU_LIST.findIndex((menu) => menu.href === location.pathname)
  );

  const [isMobile, setIsMobile] = useState(false);
  const [isOpenMenu, setIsOpenMenu] = useState(false);

  // Track if navbar should be black when on /zti route
  const [isBlackNavbar, setIsBlackNavbar] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1024);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const [navbarColor, setNavbarColor] = useState("");
  useEffect(() => {
    if (location.pathname === "/") {
      setNavbarColor("transparent");
    } else if (location.pathname === "/zti") {
      setNavbarColor("black");
    } else {
      setNavbarColor("white");
    }
  }, [location.pathname]);

  useEffect(() => {
    if (location.pathname === "/zti" || location.pathname === "/") {
      setIsBlackNavbar(true); // Set navbar color to black
    } else {
      setIsBlackNavbar(false); // Set navbar color to default when not on '/zti'
    }
  }, [location.pathname]);

  useEffect(() => {
    const handleScroll = () => {
      if (location.pathname === "/") {
        if (window.scrollY > 50) {
          setNavbarColor("black");
        } else {
          setNavbarColor("transparent");
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [location.pathname]);

  const toggleMenu = () => {
    setIsOpenMenu(!isOpenMenu);
  };

  const handleListItemClick = (idx) => {
    setActiveIndex(idx);
    setIsOpenMenu(false); // Close the drawer when a list item is clicked
  };

  const getLinkStyle = (idx) => {
    const activeColor = idx === activeIndex ? { color: "#43ba80" } : {};

    const textColor = isBlackNavbar ? { color: "white" } : { color: "black" };

    return {
      ...textColor,
      ...activeColor,
    };
  };

  const buttonStyles = {
    color: isBlackNavbar ? "#FFFFFF" : "#000000",
    fontSize: "14px",
    textTransform: "none",
    fontFamily: "Manrope",
    textDecoration: "none",
    width: "90px",
    "&:hover": {
      backgroundColor: "transparent",
      border: "1px solid #040714",
      borderRadius: "10px",
    },
  };

  const drawerContent = (
    <>
      <List>
        {MENU_LIST.map((menu, idx) => (
          <ListItem
            key={menu.text}
            component={Link}
            to={menu.href}
            onClick={() => handleListItemClick(idx)}
            style={{
              ...getLinkStyle(idx),
              color: "black",
            }}
          >
            <ListItemText primary={menu.text} />
          </ListItem>
        ))}

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 3,
            alignItems: "start",
            mt: 2,
            px: 2,
          }}
        >
          <Link to="https://mock.testseries.mgate.in/signup" target="_blank">
            Test Series{" "}
          </Link>
          <Link to="https://learn.msigmagokulam.com/login" target="_blank">
            Sign In
          </Link>
        </Box>
      </List>
      <Divider />
    </>
  );

  return (
    <div
      className={`${style.wrapper} ${
        location.pathname !== "/" ? style.addMargin : ""
      }`}
    >
      <AppBar
        position="fixed"
        style={{
          height: "100px",
          display: "flex",
          justifyContent: "center",
          backgroundColor: navbarColor,
          boxShadow: "none",
        }}
      >
        <Toolbar
          sx={{
            justifyContent: "space-between",
            margin: { lg: "0 50px" },
          }}
        >
          {isMobile ? (
            <>
              <div
                className={style.navBarLogoSect}
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "420px",
                  justifyContent: "space-between",
                }}
              >
                <Link to="/">
                  <img
                    alt="msigma Logo"
                    src={mobLogo}
                    width="100px"
                    className={style.logo}
                    style={{
                      filter: isBlackNavbar ? "invert(1)" : "none",
                    }}
                  />
                </Link>
              </div>
              <IconButton
                edge="start"
                color="black"
                aria-label="menu"
                onClick={toggleMenu}
                className={style.menuIcon}
                style={{
                  filter: isBlackNavbar ? "invert(1)" : "none",
                }}
              >
                {" "}
                <Link to="/"></Link>
                <MenuIcon
                  sx={{
                    fontSize: "44px",
                    color: "black",
                  }}
                />
              </IconButton>
              <Drawer
                anchor="right"
                open={isOpenMenu}
                onClose={toggleMenu}
                PaperProps={{ sx: { width: 240, alignItems: "center" } }}
              >
                {drawerContent}
              </Drawer>
            </>
          ) : (
            <>
              <div
                className={style.navBarLogoSect}
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "420px",
                  justifyContent: "space-between",
                }}
              >
                <Link to="/">
                  <img
                    alt="msigma Logo"
                    src={Logo}
                    width="200px"
                    className={style.logo}
                    style={{
                      filter: isBlackNavbar ? "invert(1)" : "none",
                    }}
                  />
                </Link>
              </div>
              <div className={style.desktopMenu}>
                {MENU_LIST.map((menu, idx) => (
                  <div key={menu.text}>
                    <Link
                      to={menu.href}
                      style={getLinkStyle(idx)}
                      onClick={() => setActiveIndex(idx)}
                    >
                      {menu.text}
                    </Link>
                  </div>
                ))}
                <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
                  <Link
                    to="https://mock.testseries.mgate.in/signup"
                    target="_blank"
                  >
                    <Button sx={buttonStyles}>Test Series</Button>
                  </Link>
                  <Link
                    to="https://learn.msigmagokulam.com/login"
                    target="_blank"
                  >
                    <Button sx={buttonStyles}>Sign In</Button>
                  </Link>
                </Box>
              </div>
            </>
          )}
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default Navbar;
