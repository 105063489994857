import Course from "components/Home/Courses/Course";
import About from "components/Home/About/About";
import Banner from "components/Home/Banner/Banner";
import Masters from "components/Home/Masters/Masters";
import Algorithm from "components/Home/Algorithm/Algorithm";
import Student from "components/Home/StudentSays/Student";
import Download from "components/Home/Download/Download";
import Vid from "components/Home/Video/Vid";
import Video from "components/Home/Video/Video";
import Blog from "components/Home/Blog/Blog";
import ZtiBanner from "components/Home/ZtiBanner/ZtiBanner";
import ZtiAbout from "components/Home/ZtiAbout/ZtiAbout";
import { lazy, Suspense, useState } from "react";

import AdsPopup from "./AdsPopup";

import Preloader from "components-shared/Preloader/Preloader";

const HomePage = () => {
  const [openPopup, setOpenPopup] = useState(true);

  const addButtonClick = () => {
    setOpenPopup(true);
  };

  const closePopup = () => {
    setOpenPopup(false);
  };

  return (
    <>
      {openPopup && <AdsPopup onclose={closePopup} />}
      {/* <Banner /> */}
      <ZtiBanner />
      <Suspense fallback={Preloader}>
        <ZtiAbout />
      </Suspense>
      <Suspense fallback={Preloader}>
        <About />
      </Suspense>
      <Suspense fallback={Preloader}>
        <Course />
      </Suspense>
      <Suspense fallback={Preloader}>
        <Masters />
      </Suspense>
      <Suspense fallback={Preloader}>
        <Algorithm />
      </Suspense>
      <Suspense fallback={Preloader}>
        <Student />
      </Suspense>
      <Suspense fallback={Preloader}>
        <Video />
      </Suspense>
      <Suspense fallback={Preloader}>{/* <Blog/> */}</Suspense>
      <Suspense fallback={Preloader}>
        <Download />
      </Suspense>
    </>
  );
};

export default HomePage;
