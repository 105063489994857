import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import RedirectImg from "../../assets/images/redirection.png";

function Redirection() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [errors, setErrors] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(true);

  const SteinStore = require("stein-js-client");
  const store = new SteinStore(
    "https://api.steinhq.com/v1/storages/67cfc14bc088333365803268"
  );

  const [formData, setFormData] = useState({
    full_name: "",
    phone_number: "",
    college: "",
    batch: "",
    branch: "",
  });

  const branches = ["AEI", "CE", "CSE", "ECE", "EEE", "EIE", "ME", "Other"];
  const batches = [
    "First Year",
    "Second Year",
    "Third Year",
    "Fourth Year",
    "Pass Out",
  ];

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const validatePhoneNumber = (phone) => {
    const phoneRegex = /^[6789]\d{9}$/;
    return phoneRegex.test(phone) && phone.length === 10;
  };

  const handlePhoneBlur = () => {
    if (!validatePhoneNumber(formData.phone_number)) {
      setErrors({
        ...errors,
        phone_number: "Invalid phone number",
      });
    } else {
      const { phone_number, ...rest } = errors;
      setErrors(rest);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let newErrors = {};

    if (!validatePhoneNumber(formData.phone_number)) {
      newErrors.phone_number = "Invalid phone number.";
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    setLoading(true);
    store
      .append("Sheet1", [formData])
      .then((res) => {
        setLoading(false);
        setIsSubmitted(true);
        closeModal();
      })
      .catch((error) => {
        setErrors({
          submit: "Error submitting form data. Please try again later.",
        });
        console.error("Error submitting form data:", error);
        alert("Error submitting form data. Please try again later.");
      });
  };

  const handleRedirect = () => {
    navigate("/qr");
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        gap: "20px",
        backgroundColor: "#f4f4f4",
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
        margin: "auto",
        marginTop: "30px",
      }}
    >
      {isModalOpen && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 1000,
          }}
        >
          <div
            style={{
              backgroundColor: "white",
              padding: "20px",
              borderRadius: "10px",
              boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.3)",
              width: "400px",
              position: "relative",
            }}
          >
            <button
              onClick={closeModal}
              style={{
                position: "absolute",
                top: "10px",
                right: "10px",
                backgroundColor: "transparent",
                border: "none",
                fontSize: "20px",
                cursor: "pointer",
              }}
            >
              &times;
            </button>

            <form
              onSubmit={handleSubmit}
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "15px",
                width: "100%",
              }}
            >
              <p
                style={{
                  fontSize: "18px",
                  fontWeight: "700",
                  textAlign: "center",
                }}
              >
                Register Now
              </p>
              <input
                type="text"
                name="full_name"
                placeholder="Full Name"
                value={formData.full_name}
                onChange={handleChange}
                required
                style={{
                  padding: "10px",
                  borderRadius: "5px",
                  border: "1px solid #ccc",
                  fontSize: "16px",
                  color: "black",
                }}
              />
              <input
                type="tel"
                name="phone_number"
                placeholder="Phone Number"
                value={formData.phone_number}
                onChange={handleChange}
                onBlur={handlePhoneBlur}
                required
                maxLength={10}
                style={{
                  padding: "10px",
                  borderRadius: "5px",
                  border: "1px solid #ccc",
                  fontSize: "16px",
                  color: "black",
                }}
              />
              {errors.phone_number && (
                <span style={{ color: "red", fontSize: "14px" }}>
                  {errors.phone_number}
                </span>
              )}
              <input
                type="text"
                name="college"
                placeholder="College"
                value={formData.college}
                onChange={handleChange}
                required
                style={{
                  padding: "10px",
                  borderRadius: "5px",
                  border: "1px solid #ccc",
                  fontSize: "16px",
                  color: "black",
                }}
              />
              <select
                name="batch"
                value={formData.batch}
                onChange={handleChange}
                required
                style={{
                  padding: "10px",
                  borderRadius: "5px",
                  border: "1px solid #ccc",
                  fontSize: "16px",
                  backgroundColor: "white",
                  color: "black",
                  height: "45px",
                }}
              >
                <option value="">Select Batch</option>
                {batches.map((batch) => (
                  <option key={batch} value={batch}>
                    {batch}
                  </option>
                ))}
              </select>

              <select
                name="branch"
                value={formData.branch}
                onChange={handleChange}
                required
                style={{
                  padding: "10px",
                  borderRadius: "5px",
                  border: "1px solid #ccc",
                  fontSize: "16px",
                  backgroundColor: "white",
                  color: "black",
                  height: "45px",
                }}
              >
                <option value="">Select Branch</option>
                {branches.map((branch) => (
                  <option key={branch} value={branch}>
                    {branch}
                  </option>
                ))}
              </select>
              <button
                type="submit"
                disabled={loading}
                style={{
                  padding: "12px",
                  borderRadius: "5px",
                  border: "none",
                  backgroundColor: "#24D17E",
                  color: "white",
                  fontSize: "16px",
                  cursor: "pointer",
                  transition: "background-color 0.3s",
                }}
              >
                {loading ? "Submitting..." : "Submit"}
              </button>
            </form>
          </div>
        </div>
      )}

      <img src={RedirectImg} style={{ width: "100%" }} />
      <button
        onClick={handleRedirect}
        style={{
          position: "fixed",
          bottom: 30,
          zIndex: 1000,
          padding: "14px",
          borderRadius: "35px",
          border: "none",
          backgroundColor: "#24D17E",
          color: "white",
          fontSize: "16px",
          cursor: "pointer",
          transition: "background-color 0.3s",
          height: "45px",
          fontWeight: "700",
          display: "flex",
          alignItems: "center",
          width: "95%",
          justifyContent: "center",
        }}
      >
        Download App Now
      </button>
    </div>
  );
}

export default Redirection;
