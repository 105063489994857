import React from "react";
import { Typography, Box, Grid, Button } from "@mui/material";
import style from "./ZtiAbout.module.scss";
import aibrain from "../../../assets/icons/aibrain.png";
import roadto from "../../../assets/icons/roadto.png";
import earth from "../../../assets/icons/earth.png";
import ztifaculties from "../../../assets/images/ztiaboutfaculties.webp";
import cardFooterImg from "../../../assets/images/cardFooterImg.png";
import playStore from "../../../assets/images/playstore.png";
import appStore from "../../../assets/images/appstore.png";

function ZtiAbout() {
	const courses = ["GATE", "IES", "PSU's", "ISRO", "CAT"];
	const handlePlayStoreClick = () => {
		window.open(
			"https://play.google.com/store/apps/details?id=in.mgate.msigma&pcampaignid=web_share",
			"_blank",
			"noopener,noreferrer"
		);
	};
	const handleappStoreClick = () => {
		window.open(
			"https://apps.apple.com/in/app/msigma/id6463402970",
			"_blank",
			"noopener,noreferrer"
		);
	};

	return (
		<Box className={style.deskTopWrapper}>
			<Grid container>
				<Grid item xs={12}>
					<Typography
						textAlign="center"
						color="white"
						fontWeight="1000"
						fontSize={{ xs: "30px", sm: "25px", md: "45px", lg: "50px" }}
						fontFamily="'Helvetica Neue', sans-serif"
					>
						WELCOME TO ZERO TO INFINITY!
					</Typography>
					<Typography
						textAlign="center"
						color="white"
						fontWeight="normal"
						fontSize={{ xs: "16px", sm: "18px", md: "22px", lg: "30px" }}
						fontFamily="'Helvetica Neue', sans-serif"
					>
						ALL-IN-ONE SOLUTION FOR ENGINEERING{" "}
					</Typography>
				</Grid>
				<Grid item xs={12} sx={{ marginTop: { xs: "50px", md: "80px" } }}>
					<Grid container gap="30px" justifyContent="center">
						<Grid
							item
							xs={12}
							md={12}
							lg={5}
							display="flex"
							sx={{
								justifyContent: { xs: "center", md: "center", lg: "right" },
							}}
						>
							<Box
								sx={{
									width: "93%",
									height: "100%",
									minHeight: "353px",
									backgroundColor: "#151515",
									color: "white",
									p: 4,
									borderRadius: "0 25px 0 25px",
									border: ".6px solid transparent",
									backgroundImage:
										"linear-gradient(rgba(21, 21, 21, 95%), rgba(21, 21, 21, 1)), linear-gradient(90deg, #3878F2, #24D17E)",
									backgroundOrigin: "border-box",
									backgroundClip: "padding-box, border-box",
								}}
							>
								<Typography
									color="white"
									fontWeight="normal"
									fontSize={{ xs: "20px", sm: "22px", md: "24px", lg: "27px" }}
									fontFamily="'Helvetica Neue', sans-serif"
									textTransform="uppercase"
									gutterBottom
								>
									B.Tech Classes{" "}
								</Typography>
								<Typography
									fontWeight="normal"
									fontSize={{ xs: "14px", sm: "15px", md: "16px", lg: "18px" }}
									fontFamily="'Helvetica Neue', sans-serif"
									sx={{
										lineHeight: {
											xs: "1.75",
											sm: "1.75",
											md: "2.25",
											lg: "2.45",
										},
									}}
								>
									<span style={{ color: "#26DD84", fontWeight: "bold" }}>
										Top-Notch Coaching for All 4-Year B.Tech Subjects
									</span>
									<br />
									Covers University Syllabus with Expert Faculty <br />
									Internal Exam Training & Real-World Applications <br />
									Hands-On{" "}
									<span style={{ color: "#26DD84", fontWeight: "bold" }}>
										Workshops
									</span>{" "}
									& Practical Learning <br />
									<span style={{ color: "#26DD84", fontWeight: "bold" }}>
										Model Exams
									</span>{" "}
									for University Preparation
								</Typography>
							</Box>
						</Grid>
						<Grid
							item
							xs={12}
							md={12}
							lg={5}
							display="flex"
							sx={{
								justifyContent: { xs: "center", md: "center", lg: "left" },
							}}
						>
							{" "}
							<Box
								sx={{
									width: "93%",
									height: "100%",
									minHeight: "353px",

									backgroundColor: "#151515",
									color: "white",
									p: 4,
									borderRadius: "0 25px 0 25px",
									border: ".6px solid transparent",
									backgroundImage:
										"linear-gradient(rgba(21, 21, 21, 95%), rgba(21, 21, 21, 1)), linear-gradient(90deg, #3878F2, #24D17E)",
									backgroundOrigin: "border-box",
									backgroundClip: "padding-box, border-box",
								}}
							>
								<Typography
									color="white"
									fontWeight="normal"
									fontSize={{ xs: "20px", sm: "22px", md: "24px", lg: "27px" }}
									fontFamily="'Helvetica Neue', sans-serif"
									textTransform="uppercase"
									gutterBottom
								>
									Competitive Exam Coaching{" "}
								</Typography>
								<Box sx={{ maxWidth: "400px" }}>
									{courses.map((word, index) => (
										<Typography
											key={index}
											color="white"
											fontWeight="normal"
											fontSize={{
												xs: "16px",
												sm: "20px",
												md: "24px",
												lg: "28px",
											}}
											fontFamily="'Helvetica Neue', sans-serif"
											textTransform="uppercase"
											gutterBottom
											sx={{
												backgroundColor: "#3878F2",
												padding: "7px 11px",
												display: "inline-block",
												marginRight: "10px",
											}}
										>
											{word}
										</Typography>
									))}
								</Box>
								<Box
									sx={{
										display: "flex",
										alignItems: "center",
										gap: "12px",
										marginTop: "30px",
									}}
								>
									<img src={aibrain} />

									<Typography
										fontWeight="normal"
										fontSize={{
											xs: "14px",
											sm: "15px",
											md: "16px",
											lg: "18px",
										}}
										fontFamily="'Helvetica Neue', sans-serif"
										color="#24D17E"
										gap="10px"
									>
										<span
											style={{
												fontWeight: "bold",
												whiteSpace: "nowrap",
											}}
										>
											AI-Powered Career App{" "}
										</span>
										<span style={{ color: "white", fontWeight: "bold" }}>
											to guide your Success!
										</span>{" "}
									</Typography>
								</Box>
							</Box>
						</Grid>
						<Grid
							item
							xs={12}
							md={12}
							lg={5}
							display="flex"
							sx={{
								justifyContent: { xs: "center", md: "center", lg: "right" },
							}}
						>
							{" "}
							<Box
								sx={{
									width: "93%",
									height: "100%",
									minHeight: "353px",

									backgroundColor: "#151515",
									color: "white",
									p: 4,
									borderRadius: "0 25px 0 25px",

									border: ".6px solid transparent",
									backgroundImage:
										"linear-gradient(rgba(21, 21, 21, 95%), rgba(21, 21, 21, 1)), linear-gradient(270deg, #3878F2, #24D17E)",
									backgroundOrigin: "border-box",
									backgroundClip: "padding-box, border-box",
								}}
							>
								<Typography
									color="white"
									fontWeight="normal"
									fontSize={{ xs: "20px", sm: "22px", md: "24px", lg: "27px" }}
									fontFamily="'Helvetica Neue', sans-serif"
									textTransform="uppercase"
									gutterBottom
								>
									Job - Guaranteed Skill Courses{" "}
								</Typography>{" "}
								<Typography
									color="white"
									fontWeight="normal"
									fontSize={{ xs: "16px", sm: "17px", md: "20px", lg: "25px" }}
									fontFamily="'Helvetica Neue', sans-serif"
									textTransform="uppercase"
									gutterBottom
									sx={{
										backgroundColor: "#007AFF",
										padding: "5px 9px",
										display: "inline-block",
										marginRight: "10px",
									}}
								>
									Coding Mastery
								</Typography>
								<Typography
									color="white"
									fontWeight="normal"
									fontSize={{ xs: "14px", sm: "17px", md: "18px", lg: "20px" }}
									fontFamily="'Helvetica Neue', sans-serif"
									textTransform="uppercase"
									gutterBottom
								>
									From basics to advanced with real-world projects{" "}
								</Typography>{" "}
								<Typography
									color="white"
									fontWeight="normal"
									fontSize={{ xs: "16px", sm: "17px", md: "20px", lg: "25px" }}
									fontFamily="'Helvetica Neue', sans-serif"
									textTransform="uppercase"
									gutterBottom
									sx={{
										backgroundColor: "#007AFF",
										padding: "5px 9px",
										display: "inline-block",
										marginRight: "10px",
										marginTop: "17px",
									}}
								>
									AI & Machine Learning{" "}
								</Typography>
								<Typography
									color="white"
									fontWeight="normal"
									fontSize={{ xs: "14px", sm: "17px", md: "18px", lg: "20px" }}
									fontFamily="'Helvetica Neue', sans-serif"
									textTransform="uppercase"
									gutterBottom
								>
									Industry-ready AI, ML & Data Science{" "}
								</Typography>{" "}
							</Box>
						</Grid>
						<Grid
							item
							xs={12}
							md={12}
							lg={5}
							display="flex"
							sx={{
								justifyContent: { xs: "center", md: "center", lg: "left" },
							}}
						>
							{" "}
							<Box
								sx={{
									width: "93%",
									height: "100%",
									minHeight: "353px",

									backgroundColor: "#151515",
									color: "white",
									p: 4,
									borderRadius: "0 25px 0 25px",

									border: ".6px solid transparent",
									backgroundImage:
										"linear-gradient(rgba(21, 21, 21, 95%), rgba(21, 21, 21, 1)), linear-gradient(170deg, #3878F2, #24D17E)",
									backgroundOrigin: "border-box",
									backgroundClip: "padding-box, border-box",
								}}
							>
								<Typography
									color="white"
									fontWeight="normal"
									fontSize={{ xs: "20px", sm: "22px", md: "24px", lg: "27px" }}
									fontFamily="'Helvetica Neue', sans-serif"
									textTransform="uppercase"
									gutterBottom
								>
									Skill Training & Industry Exposure{" "}
								</Typography>{" "}
								<Typography
									fontWeight="normal"
									fontSize={{ xs: "14px", sm: "15px", md: "16px", lg: "18px" }}
									fontFamily="'Helvetica Neue', sans-serif"
									sx={{
										lineHeight: {
											xs: "1.75",
											sm: "1.75",
											md: "2.25",
											lg: "2.45",
										},
									}}
								>
									<span style={{ color: "#26DD84", fontWeight: "bold" }}>
										Develop Cutting-Edge Skills{" "}
									</span>
									<br />
									AI Training & Coding Projects <br />
									<span style={{ color: "#26DD84", fontWeight: "bold" }}>
										Aptitude Training
									</span>{" "}
									to Enhance Logical Skills <br />
									<span style={{ color: "#26DD84", fontWeight: "bold" }}>
										Industrial Training{" "}
									</span>{" "}
									Aligned with Latest Trends <br />
									<span style={{ color: "#26DD84", fontWeight: "bold" }}>
										Internship during All Semesters{" "}
									</span>{" "}
									for Hands-on Experience{" "}
								</Typography>
								<Typography
									fontWeight="normal"
									fontSize={{ xs: "14px", sm: "15px", md: "16px", lg: "18px" }}
									fontFamily="'Helvetica Neue', sans-serif"
									color="#3878F2"
									display="flex"
									alignItems="center"
									gap="10px"
									marginTop="30px"
								>
									<img src={earth} />
									Gain Real-World Industry Experience!{" "}
								</Typography>
							</Box>
						</Grid>
						<Grid
							item
							xs={12}
							md={12}
							lg={5}
							display="flex"
							sx={{
								justifyContent: { xs: "center", md: "center", lg: "right" },
							}}
						>
							{" "}
							<Box
								sx={{
									width: "93%",
									height: "100%",
									minHeight: "353px",

									backgroundColor: "#151515",
									color: "white",
									p: 4,
									borderRadius: "0 25px 0 25px",

									border: ".6px solid transparent",
									backgroundImage:
										"linear-gradient(rgba(21, 21, 21, 95%), rgba(21, 21, 21, 1)), linear-gradient(170deg, #3878F2, #24D17E)",
									backgroundOrigin: "border-box",
									backgroundClip: "padding-box, border-box",
								}}
							>
								<Typography
									color="white"
									fontWeight="normal"
									fontSize={{ xs: "20px", sm: "22px", md: "24px", lg: "27px" }}
									fontFamily="'Helvetica Neue', sans-serif"
									textTransform="uppercase"
									gutterBottom
								>
									Technical & Soft Skills Mastery{" "}
								</Typography>{" "}
								<Typography
									fontWeight="normal"
									fontSize={{ xs: "14px", sm: "15px", md: "16px", lg: "18px" }}
									fontFamily="'Helvetica Neue', sans-serif"
									sx={{
										lineHeight: {
											xs: "1.75",
											sm: "1.75",
											md: "2.25",
											lg: "2.45",
										},
									}}
								>
									<span style={{ color: "#26DD84", fontWeight: "bold" }}>
										Boost Your Employability{" "}
									</span>
									<br />
									<span style={{ color: "#26DD84", fontWeight: "bold" }}>
										Placement Assistance{" "}
									</span>{" "}
									with Expert Career Coaching <br />
									<span style={{ color: "#26DD84", fontWeight: "bold" }}>
										Coding Training{" "}
									</span>{" "}
									from Beginner to Advanced Level <br />
									<span style={{ color: "#26DD84", fontWeight: "bold" }}>
										Personality Development{" "}
									</span>{" "}
									& Soft Skills Workshops{" "}
								</Typography>
								<Typography
									fontWeight="normal"
									fontSize={{ xs: "14px", sm: "15px", md: "16px", lg: "18px" }}
									fontFamily="'Helvetica Neue', sans-serif"
									color="#FFB844"
									display="flex"
									alignItems="center"
									gap="10px"
									marginTop="30px"
								>
									<img src={roadto} />
									Your Road to a Dream Job Starts Here!
								</Typography>
							</Box>
						</Grid>
						<Grid
							item
							xs={12}
							md={12}
							lg={5}
							display="flex"
							sx={{
								justifyContent: { xs: "center", md: "center", lg: "left" },
							}}
						>
							{" "}
							<Box
								sx={{
									width: "93%",
									height: "100%",
									minHeight: "353px",

									backgroundColor: "#151515",
									color: "white",
									p: 4,
									borderRadius: "0 25px 0 25px",

									border: ".6px solid transparent",
									backgroundImage:
										"linear-gradient(rgba(21, 21, 21, 95%), rgba(21, 21, 21, 1)), linear-gradient(90deg, #3878F2, #24D17E)",
									backgroundOrigin: "border-box",
									backgroundClip: "padding-box, border-box",
								}}
							>
								<Typography
									color="white"
									fontWeight="normal"
									fontSize={{ xs: "20px", sm: "22px", md: "24px", lg: "27px" }}
									fontFamily="'Helvetica Neue', sans-serif"
									textTransform="uppercase"
									gutterBottom
								>
									Career Readiness & Communication Skills{" "}
								</Typography>{" "}
								<Typography
									fontWeight="normal"
									fontSize={{ xs: "14px", sm: "15px", md: "16px", lg: "18px" }}
									fontFamily="'Helvetica Neue', sans-serif"
									sx={{
										lineHeight: {
											xs: "1.75",
											sm: "1.75",
											md: "2.25",
											lg: "2.45",
										},
									}}
								>
									<span style={{ color: "#26DD84", fontWeight: "bold" }}>
										Become Industry-Ready!{" "}
									</span>
									<br />
									<span style={{ color: "#26DD84", fontWeight: "bold" }}>
										Interview Training
									</span>{" "}
									to Crack Job Interviews <br />
									<span style={{ color: "#26DD84", fontWeight: "bold" }}>
										Communication Development
									</span>{" "}
									to Enhance Professional Speaking Skills <br />
									Life Skills & Leadership Development <br />
									One-on-One Mentoring & Networking
								</Typography>
							</Box>
						</Grid>
						<Grid
							item
							xs={12}
							md={12}
							lg={5}
							display="flex"
							sx={{
								justifyContent: { xs: "center", md: "center", lg: "right" },
							}}
						>
							{" "}
							<Box
								sx={{
									width: "93%",
									height: "100%",
									minHeight: "353px",

									backgroundColor: "#151515",
									color: "white",
									p: 4,
									borderRadius: "0 25px 0 25px",

									border: ".6px solid transparent",
									backgroundImage:
										"linear-gradient(rgba(21, 21, 21, 95%), rgba(21, 21, 21, 1)), linear-gradient(150deg, #3878F2, #24D17E)",
									backgroundOrigin: "border-box",
									backgroundClip: "padding-box, border-box",
									position: "relative",
								}}
							>
								<Typography
									color="white"
									fontWeight="normal"
									fontSize={{ xs: "20px", sm: "22px", md: "24px", lg: "27px" }}
									fontFamily="'Helvetica Neue', sans-serif"
									textTransform="uppercase"
									gutterBottom
								>
									EDGE Program & Campus Placement{" "}
								</Typography>{" "}
								<Typography
									fontWeight="normal"
									fontSize={{ xs: "14px", sm: "15px", md: "16px", lg: "18px" }}
									fontFamily="'Helvetica Neue', sans-serif"
									sx={{
										lineHeight: {
											xs: "1.75",
											sm: "1.75",
											md: "2.25",
											lg: "2.45",
										},
									}}
								>
									<span style={{ color: "#26DD84", fontWeight: "bold" }}>
										Education for Dynamic Growth and Employability (EDGE){" "}
									</span>
									<br />
									Collaborations with Foreign Universities
									<br />
									Open-Source Contributions & Research
									<br />
									Assistance in Publishing Papers & Patents
									<br />
									NGO & Corporate Tie-ups for Career Growth
									<br />{" "}
								</Typography>
								<Typography
									fontWeight="normal"
									fontSize={{ xs: "14px", sm: "15px", md: "16px", lg: "18px" }}
									fontFamily="'Helvetica Neue', sans-serif"
									fontStyle="italic"
									color="#02B8FF"
								>
									Campus Placement Conducted by MSIGMA GOKULAM
								</Typography>
								<Typography
									fontWeight="normal"
									fontSize="13px"
									fontFamily="'Helvetica Neue', sans-serif"
								>
									We, MSIGMA GOKULAM, are bringing top companies to your final
									year for a Mega Campus Placement Drive!{" "}
								</Typography>
							</Box>
						</Grid>
						<Grid
							item
							xs={12}
							md={12}
							lg={5}
							display="flex"
							sx={{
								justifyContent: { xs: "center", md: "center", lg: "left" },
							}}
						>
							<Box
								sx={{
									width: "93%",
									minHeight: {
										xs: "490px",
										sm: "410px",
										md: "410px",
										lg: "410px",
									},
									backgroundColor: "#151515",
									color: "white",
									p: { xs: 3, md: 4 },
									borderRadius: "0 25px 0 25px",
									border: ".6px solid transparent",
									backgroundImage:
										"linear-gradient(rgba(21, 21, 21, 95%), rgba(21, 21, 21, 1)), linear-gradient(150deg, #3878F2, #24D17E)",
									backgroundOrigin: "border-box",
									backgroundClip: "padding-box, border-box",
									display: "flex",
									alignItems: "center",
									flexDirection: "column",
									position: "relative",
								}}
							>
								<Typography
									color="white"
									fontWeight="normal"
									fontSize={{ xs: "20px", sm: "22px", md: "24px", lg: "27px" }}
									fontFamily="'Helvetica Neue', sans-serif"
									textTransform="uppercase"
									textAlign="center"
								>
									ZERO TO INFINITY – LIMITED-TIME OFFER!
								</Typography>
								<Typography
									color="white"
									fontWeight="normal"
									fontSize={{ xs: "15px", sm: "15px", md: "16px", lg: "18px" }}
									fontFamily="'Helvetica Neue', sans-serif"
									textTransform="uppercase"
									gutterBottom
									textAlign="center"
								>
									Your 4-Year B.Tech Journey – All-In-One Course!
								</Typography>
								<Typography
									color="#1EFFC7"
									fontWeight="normal"
									fontFamily="Tiempos Text Medium Italic"
									sx={{
										fontSize: {
											xs: "22px",
											sm: "26px",
											md: "28px",
											md: "32px",
										},
									}}
								>
									Early Bird Offer
								</Typography>

								<Box
									sx={{
										display: "flex",
										alignItems: "center",
										justifyContent: "center",
										backgroundColor: "#00ffe0",
										padding: "5px 16px",
										fontWeight: "bold",
										flexWrap: "wrap",
									}}
								>
									<Typography
										fontSize={{ xs: "34px", sm: "38px", md: "44px" }}
										fontWeight="900"
										color="black"
										fontFamily="'Helvetica Neue', sans-serif"
									>
										₹15,000
									</Typography>
									<Box
										sx={{
											position: "relative",
											display: "inline-block",
											ml: "8px",
										}}
									>
										<Typography
											fontSize={{ xs: "30px", sm: "38px", md: "44px" }}
											fontWeight="900"
											color="black"
											ml="20px"
											fontFamily="'Helvetica Neue', sans-serif"
										>
											₹50,000
										</Typography>
										<Box
											sx={{
												position: "absolute",
												top: "50%",
												width: "100%",
												height: "3px",
												backgroundColor: "black",
												ml: "10px",
											}}
										/>
									</Box>
								</Box>

								<Box
									sx={{
										display: "flex",
										alignItems: "center",
										justifyContent: "center",
										padding: "8px 16px",
										borderRadius: "4px",
										color: "white",
										flexWrap: "wrap",
										textAlign: "center",
									}}
								>
									<Typography
										fontSize={{ xs: "14px", sm: "16px", md: "17px" }}
										fontWeight="normal"
										textTransform="uppercase"
										fontFamily="'Helvetica Neue', sans-serif"
										mr="12px"
									>
										FOR 4 YEARS!
									</Typography>
									<Typography
										fontSize={{ xs: "14px", sm: "16px", md: "17px" }}
										fontWeight="normal"
										textTransform="uppercase"
										fontFamily="'Helvetica Neue', sans-serif"
									>
										USE CODE:
									</Typography>
									<Box
										sx={{
											backgroundColor: "#007AFF",
											color: "white",
											padding: "4px 8px",
											ml: "8px",
										}}
									>
										<Typography
											fontSize={{ xs: "14px", sm: "16px", md: "17px" }}
											fontWeight="normal"
											textTransform="uppercase"
											textAlign="center"
											fontFamily="'Helvetica Neue', sans-serif"
										>
											EARLYBIRD70
										</Typography>
									</Box>
								</Box>

								<Box
									sx={{
										position: "absolute",
										bottom: "0",
										left: "0",
										width: "100%",
										display: "flex",
										justifyContent: "space-evenly",
										alignItems: "center",
										padding: "10px",
										flexWrap: "wrap",
										flexDirection: { xs: "column-reverse", sm: "row" },
										gap: { xs: "10px", sm: "0px" },
									}}
								>
									<img
										src={cardFooterImg}
										style={{
											maxWidth: "200px",
											height: "auto",
											flex: "1 1 100%",
											order: { xs: 1, sm: 0 },
											marginBottom: "-10px",
										}}
									/>

									<Box sx={{ order: { xs: 2, sm: 1 } }}>
										<Typography
											fontSize={{ xs: "16px", sm: "18px", md: "20px" }}
											fontWeight="normal"
											fontFamily="'Helvetica Neue', sans-serif"
										>
											Download Now
										</Typography>
										<Box
											sx={{
												display: "flex",
												flexDirection: "row",
												alignItems: "center",
												gap: "10px",
												justifyContent: "center",
												flexWrap: "wrap",
											}}
										>
											<img
												src={playStore}
												style={{
													maxWidth: "100px",
													height: "auto",
													cursor: "pointer",
												}}
												onClick={handlePlayStoreClick}
											/>
											<img
												src={appStore}
												style={{
													maxWidth: "100px",
													height: "auto",
													cursor: "pointer",
												}}
												onClick={handleappStoreClick}
											/>
										</Box>
									</Box>
								</Box>
							</Box>
						</Grid>
					</Grid>
				</Grid>
				<Grid
					item
					xs={12}
					sx={{
						marginTop: {
							xs: "-80px",
							md: "300pz",
							lg: "-400px",
							xl: "-400px",
						},
					}}
				>
					<img src={ztifaculties} width="100%" />
				</Grid>
			</Grid>
		</Box>
	);
}

export default ZtiAbout;
