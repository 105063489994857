import React from "react";
import JobDetails from "../../components/psuJob/JobDetails/JobDetails";

function PsuJob() {
  return (
    <div>
      <JobDetails />
    </div>
  );
}

export default PsuJob;
