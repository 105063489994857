import { Typography, Box, Button, Grid } from "@mui/material";
import style from "./ZtiBanner.module.scss";
import students from "../../../assets/images/students.png";
import ggandmr from "../../../assets/images/ggandmr.webp";

function ZtiBanner() {
  return (
    <Box className={style.deskTopWrapper} display="flex" flexDirection="column">
      <Grid>
        <Grid
          item
          display="flex"
          justifyContent="center"
          sx={{
            paddingTop: {
              xs: "100px",
            },
          }}>
          <Typography
            fontFamily="Helvetica Neue'Helvetica Neue', sans-serif"
            fontWeight="700"
            textAlign="start"
            sx={{
              fontSize: {
                xs: "30px",
                sm: "35px",
                md: "50px",
                lg: "70px",
                xl: "70px",
              },
              textTransform: "uppercase",
              backgroundImage:
                "linear-gradient(90deg, #1EFFC7 0%, #FFFFFF 65%)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}>
            Building The Nation
          </Typography>
        </Grid>
        <Grid
          item
          display="flex"
          justifyContent="center"
          // sx={{ marginTop: "-20px" }}
          sx={{
            marginTop: {
              xs: "-10px",
              sm: "-10px",
              md: "-20px",
              lg: "-20px",
              xl: "-20px",
            },
          }}>
          <Typography
            fontFamily="Helvetica Neue'Helvetica Neue', sans-serif"
            fontWeight="700"
            textAlign="start"
            sx={{
              fontSize: {
                xs: "18px",
                sm: "20px",
                md: "30px",
                lg: "40px",
                xl: "40px",
              },
              textTransform: "uppercase",
              color: "white",
              letterSpacing: "-0.5px",
            }}>
            by Crafting Best Engineers
          </Typography>
        </Grid>
      </Grid>
      <Grid
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-end",
          marginTop: {
            xs: "90px",
            sm: "70px",
            md: "30px",
            lg: "0px",
            xl: "0px",
          },
        }}>
        <img
          src={ggandmr}
          alt="Background"
          style={{ width: "70%", height: "auto", display: "block" }}
        />
      </Grid>
    </Box>
  );
}

export default ZtiBanner;
